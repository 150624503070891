import gql from "graphql-tag";

export const blocksGql = gql`
  query Blocks($filters: BlockFiltersInput) {
    blocks(filters: $filters) {
      ... on BlockResults {
        results {
          id
          epochNo
          slotNo
          slotLeader {
            hash
            poolHash {
              id
              hash
              name
              ticker
            }
          }
          blockLoadPercentage
          hash
          timestamp
          blockNo
          transactionsCount
          transactionsAmount
          transactionsFee
        }
      }

      ... on ResponseErrors {
        errors {
          message
        }
      }
    }
  }
`;
