
import { computed, defineComponent, PropType } from "vue";
import { useI18n } from "vue-i18n";
import { BlockCardDetails } from "@/shared/types/cards";
// will not include BlockCardLayout for now, conflict with arrows
import BlockCardLayoutBoxedItem from "@/shared/components/cards/BlockCardLayoutBoxedItem.vue";
import AmountText from "@/shared/components/typography/AmountText.vue";
import Tooltip from "@/shared/components/cards/Tooltip.vue";
import LabeledItem from "@/shared/components/layouts/LabeledItem.vue";
import FullWrapContainer from "@/shared/components/cards/FullWrapContainer.vue";

export default defineComponent({
  props: {
    blockCardDetails: {
      type: Object as PropType<BlockCardDetails>,
      required: true,
    },
  },
  setup(props) {
    const t = useI18n().t;

    return {
      t,
      isEven: computed(() => {
        return props.blockCardDetails.id % 2 == 0;
      }),
    };
  },
  components: {
    FullWrapContainer,
    BlockCardLayoutBoxedItem,
    AmountText,
    Tooltip,
    LabeledItem,
  },
});
