import { computed, onUnmounted, ref, toRaw, watch } from "vue";
import { useQuery } from "@vue/apollo-composable";
import {
  Blocks,
  Blocks_blocks_BlockResults,
  Blocks_blocks_BlockResults_results,
  BlocksVariables,
} from "@/api/blocks/__generated__/Blocks";
import { blocksGql } from "@/api/blocks/blockList";
import { parseGqlResponse } from "@/shared/utils/graphql/responseParser";
import { formatPoolLabel } from "@/shared/utils/stringHelpers";
import { formatDate, formatTime } from "@/shared/utils/date";
import { convertAmountToAda } from "@/shared/utils/numbers";
import { useFetchMoreListMerge } from "@/shared/composables/useMergeList";

const blockListDisplayMapper = (
  itemList: (Blocks_blocks_BlockResults_results | null)[] | null
) => {
  return (itemList ?? []).map((item) => {
    return {
      id: item?.id,
      transactionId: item?.hash,
      poolId: item?.slotLeader?.poolHash?.id,
      poolName: formatPoolLabel(
        item?.slotLeader?.poolHash?.name,
        item?.slotLeader?.poolHash?.ticker,
        item?.slotLeader?.poolHash?.hash
      ),
      block: item?.blockNo,
      epoch: item?.epochNo,
      blockLoadPercentage: item?.blockLoadPercentage * 100,
      slot: item?.slotNo,
      date: formatDate(item?.timestamp),
      time: formatTime(item?.timestamp),
      amount1: convertAmountToAda(item?.transactionsAmount),
      amount2: convertAmountToAda(item?.transactionsFee),
    };
  });
};

// TODO: Add additional filters as needed if a screen has the need to support custom filters
export const useGetBlockList = (epochNo?: number) => {
  const mergedBlockList = ref<ReturnType<typeof blockListDisplayMapper>>([]);
  const { fetchMoreListMerge } = useFetchMoreListMerge();
  const showMoreButtonTriggered = ref(false);
  const blockList = useQuery<Blocks, BlocksVariables>(blocksGql, () => ({
    filters: {
      epochNo: epochNo ?? null,
      pagination: {
        timestamp: new Date().toISOString(),
      },
    },
  }));

  const parsedGqlBlockList = computed(() =>
    parseGqlResponse<Blocks_blocks_BlockResults>(
      "BlockResults",
      blockList?.result?.value
    )
  );

  watch(parsedGqlBlockList, (newParsedGqlBlockList) => {
    if (
      newParsedGqlBlockList.data?.results &&
      newParsedGqlBlockList.data?.results.length > 1
    ) {
      const mappedDisplay = blockListDisplayMapper(
        newParsedGqlBlockList.data.results
      );

      const mergedList = fetchMoreListMerge<
        ArrayElement<ReturnType<typeof blockListDisplayMapper>>
      >(
        toRaw(mergedBlockList.value),
        mappedDisplay ?? [],
        "id",
        showMoreButtonTriggered.value
      );

      mergedBlockList.value = mergedList;
      showMoreButtonTriggered.value = false;
    }
  });

  const timerId = window.setInterval(() => {
    if (showMoreButtonTriggered.value) {
      return;
    }

    blockList.refetch({
      filters: {
        epochNo: epochNo ?? null,
        pagination: {
          timestamp: new Date().toISOString(),
          pageSize: 2,
        },
      },
    });
  }, 5000);

  const showMore = () => {
    showMoreButtonTriggered.value = true;
    blockList.refetch({
      filters: {
        epochNo: epochNo ?? null,
        pagination: {
          timestamp: new Date().toISOString(),
          offset: mergedBlockList.value.length,
        },
      },
    });
  };

  onUnmounted(() => {
    if (timerId) {
      window.clearInterval(timerId);
    }
  });

  return {
    mappedDataForDisplay: mergedBlockList,
    showMore,
    showMoreIsLoading: showMoreButtonTriggered,
    isLoading: blockList.loading,
  };
};
